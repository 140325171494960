import { margin } from '@prototyp/gatsby-plugin-gumball/utils';
import { Grid, SeoTags } from 'components';
import { motion } from 'framer-motion';
import { GatsbyImage, getImage, getSrc } from 'gatsby-plugin-image';
import { FormattedMessage, useIntl } from 'gatsby-plugin-react-intl';
import { useImages } from 'hooks';
import React from 'react';
import InView from 'react-intersection-observer';
import { Header } from './components';
import { about } from './styles/AboutPage.styles';

export const AboutPage: React.FC = () => {
  const { interregLogo, vodenaVrata } = useImages();
  const interregLogoData = getImage(interregLogo);
  const { formatMessage } = useIntl();

  return (
    <>
      <SeoTags
        title={formatMessage({ id: 'about-project.seo.title' })}
        description={formatMessage({
          id: 'about-project.seo.description',
        })}
        imagePath={getSrc(vodenaVrata)}
      />
      <Header />
      <Grid type="page">
        <div css={about.wrapper}>
          <InView threshold={0.4} triggerOnce={true}>
            {({ ref, inView }) => (
              <motion.h2
                ref={ref}
                initial={{ opacity: 0, y: '40px' }}
                animate={{ opacity: inView ? 1 : 0, y: inView ? '0' : '40px' }}
                transition={{ ease: 'easeIn', duration: 0.4 }}
                css={about.title}
              >
                <FormattedMessage id="about-project.section-organization.title" />
              </motion.h2>
            )}
          </InView>
          <div css={about.text}>
            <InView threshold={0.4} triggerOnce={true}>
              {({ ref, inView }) => (
                <motion.p
                  ref={ref}
                  initial={{ opacity: 0, y: '40px' }}
                  animate={{
                    opacity: inView ? 1 : 0,
                    y: inView ? '0' : '40px',
                  }}
                  transition={{ ease: 'easeIn', duration: 0.4 }}
                  css={[margin.bottom.med]}
                >
                  <FormattedMessage id="about-project.section-organization.paragraph-association" />
                </motion.p>
              )}
            </InView>
            <InView threshold={0.4} triggerOnce={true}>
              {({ ref, inView }) => (
                <motion.p
                  ref={ref}
                  initial={{ opacity: 0, y: '40px' }}
                  animate={{
                    opacity: inView ? 1 : 0,
                    y: inView ? '0' : '40px',
                  }}
                  transition={{ ease: 'easeIn', duration: 0.4 }}
                >
                  <FormattedMessage id="about-project.section-organization.paragraph-promotion" />
                </motion.p>
              )}
            </InView>
          </div>
          <InView threshold={0.4} triggerOnce={true}>
            {({ ref, inView }) => (
              <motion.h2
                ref={ref}
                initial={{ opacity: 0, y: '40px' }}
                animate={{
                  opacity: inView ? 1 : 0,
                  y: inView ? '0' : '40px',
                }}
                transition={{ ease: 'easeIn', duration: 0.4 }}
                css={about.title}
              >
                <FormattedMessage id="about-project.section-activities.title" />
              </motion.h2>
            )}
          </InView>
          <InView threshold={0.4} triggerOnce={true}>
            {({ ref, inView }) => (
              <motion.div
                ref={ref}
                initial={{ opacity: 0, y: '40px' }}
                animate={{
                  opacity: inView ? 1 : 0,
                  y: inView ? '0' : '40px',
                }}
                transition={{ ease: 'easeIn', duration: 0.4 }}
                css={about.text}
              >
                <p css={margin.bottom.med}>
                  <FormattedMessage id="about-project.section-activities.startup" />
                </p>
                <p css={margin.bottom.med}>
                  <FormattedMessage id="about-project.section-activities.system" />
                </p>
                <p css={margin.bottom.med}>
                  <FormattedMessage id="about-project.section-activities.agency" />
                </p>
                <p css={margin.bottom.med}>
                  <FormattedMessage id="about-project.section-activities.platform" />
                </p>
                <p css={margin.bottom.med}>
                  <FormattedMessage id="about-project.section-activities.festivals" />
                </p>
                <p css={margin.bottom.med}>
                  <FormattedMessage id="about-project.section-activities.promotion" />
                </p>
              </motion.div>
            )}
          </InView>
          <InView threshold={0.4} triggerOnce={true}>
            {({ ref, inView }) => (
              <motion.h2
                ref={ref}
                initial={{ opacity: 0, y: '40px' }}
                animate={{
                  opacity: inView ? 1 : 0,
                  y: inView ? '0' : '40px',
                }}
                transition={{ ease: 'easeIn', duration: 0.4 }}
                css={about.title}
              >
                <FormattedMessage id="about-project.section-partners.title" />
              </motion.h2>
            )}
          </InView>
          <InView threshold={0.4} triggerOnce={true}>
            {({ ref, inView }) => (
              <motion.p
                ref={ref}
                initial={{ opacity: 0, y: '40px' }}
                animate={{
                  opacity: inView ? 1 : 0,
                  y: inView ? '0' : '40px',
                }}
                transition={{ ease: 'easeIn', duration: 0.4 }}
                css={[margin.bottom.med, about.text]}
              >
                <FormattedMessage id="about-project.section-partners.association" />
              </motion.p>
            )}
          </InView>
          <InView threshold={0.4} triggerOnce={true}>
            {({ ref, inView }) => (
              <motion.p
                ref={ref}
                initial={{ opacity: 0, y: '40px' }}
                animate={{
                  opacity: inView ? 1 : 0,
                  y: inView ? '0' : '40px',
                }}
                transition={{ ease: 'easeIn', duration: 0.4 }}
                css={[margin.bottom.xxlrg, about.text]}
              >
                <FormattedMessage id="about-project.section-partners.university" />
              </motion.p>
            )}
          </InView>
          {interregLogoData && (
            <InView threshold={0.4} triggerOnce={true}>
              {({ ref, inView }) => (
                <motion.div
                  ref={ref}
                  initial={{ opacity: 0, y: '40px' }}
                  animate={{
                    opacity: inView ? 1 : 0,
                    y: inView ? '0' : '40px',
                  }}
                  transition={{ ease: 'easeIn', duration: 0.4 }}
                >
                  <GatsbyImage
                    loading="lazy"
                    image={interregLogoData}
                    alt="Interreg logo"
                  />
                </motion.div>
              )}
            </InView>
          )}
        </div>
      </Grid>
    </>
  );
};
