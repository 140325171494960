import { Grid } from 'components';
import React from 'react';
import { header } from '../styles/Header.styles';
import { Link } from 'gatsby';
import Arrow from 'assets/icons/arrowRight.svg';
import { margin, text } from '@prototyp/gatsby-plugin-gumball/utils';
import { useImages } from 'hooks';
import { getImage } from 'gatsby-plugin-image';
import { GatsbyImage } from 'gatsby-plugin-image';
import { FormattedMessage, useIntl } from 'gatsby-plugin-react-intl';
import { motion } from 'framer-motion';

export const Header: React.FC = () => {
  const { aboutProject } = useImages();
  const aboutProjectData = getImage(aboutProject);
  const { formatMessage } = useIntl();

  return (
    <section css={header.root}>
      <Grid type="page">
        <motion.h1
          initial={{ opacity: 0, y: '40px' }}
          animate={{ opacity: 1, y: '0' }}
          transition={{ ease: 'easeIn', duration: 0.3 }}
          css={header.title}
        >
          <FormattedMessage id="about-project.header.title" />
        </motion.h1>
        {aboutProjectData && (
          <motion.div
            initial={{ opacity: 0, y: '40px' }}
            animate={{ opacity: 1, y: '0' }}
            transition={{ ease: 'easeIn', duration: 0.3 }}
            css={header.img}
          >
            <GatsbyImage
              image={aboutProjectData}
              alt={formatMessage({ id: 'images.titles.kopacki-rit' })}
              loading="lazy"
            />
          </motion.div>
        )}
        <motion.div
          initial={{ opacity: 0, y: '40px' }}
          animate={{ opacity: 1, y: '0' }}
          transition={{ ease: 'easeIn', duration: 0.3 }}
          css={header.linkWrapper}
        >
          <Link
            css={[header.link, header.linkPrimary, text.color.primary]}
            to="/"
          >
            <FormattedMessage id="about-project.header.breadcrumb" />
          </Link>
          <Arrow css={margin.right.xlrg} />
          <p css={header.link}>
            <FormattedMessage id="about-project.header.title" />
          </p>
        </motion.div>
      </Grid>
    </section>
  );
};
