import { Layout } from 'components/layout';
import { AboutPage } from 'modules/about-project';
import React from 'react';

const AboutProject: React.FC = () => (
  <Layout isGhostNav={false}>
    <AboutPage />
  </Layout>
);

export default AboutProject;
