import { css } from '@emotion/react';
import { grid } from '@prototyp/gatsby-plugin-gumball/utils';
import { colors } from 'style';

const wrapper = css`
  ${grid.colSpan.c6}
  ${grid.colStart.c4}
  padding-top: calc(var(--unit) * 12);
  padding-bottom: calc(var(--unit) * 22);
  font-family: var(--font-family-secondary);
  font-size: 16px;
  line-height: 24px;
`;

const title = css`
  font-family: var(--font-family-secondary);
  font-weight: var(--font-weight-custom500);
  font-size: calc(var(--unit) * 5);
  line-height: calc(var(--unit) * 7);
`;

const titlePrimary = css`
  color: ${colors.text};
  font-family: var(--font-family-primary);
  font-weight: normal;
  font-size: calc(var(--unit) * 9);
  line-height: calc(var(--unit) * 12);
  margin-bottom: var(--spacing-huge);
`;

const text = css`
  color: ${colors.textLight};
  margin: var(--spacing-huge) 0;
  font-family: var(--font-family-secondary);
  font-size: calc(var(--unit) * 4);
  line-height: calc(var(--unit) * 6);
`;

const image = css`
  max-width: 100%;
  width: auto;
`;

export const about = {
  wrapper,
  title,
  titlePrimary,
  text,
  image,
};
